<template>
<v-app>
    <div class="frame-popup">
        <header>
            <h1>고객정보</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </header>
        <main>
            <div class="jh-search-form">
                <table>
                    <colgroup>
                        <col width="50px">
                        <col width="120px">
                        <col width="90px">
                        <col width="120px">
                        <col width="90px">
                        <col width="150px">
                        <col>
                    </colgroup>
                    <tr>
                        <th><label>고객명</label></th>
                        <td><input type="text" class="jh-form"></td>
                        <th><label>생년월일</label></th>
                        <td><input type="text" class="jh-form"></td>
                        <th><label>전화번호</label></th>
                        <td><input type="text" class="jh-form"></td>
                        <td class="has-search"><v-btn class="jh-btn is-search" @click="">조회</v-btn></td>
                    </tr>
                </table>
            </div>
            <div class="jh-form-wrap">
                <div class="jh-ui-header">
                    <h2>고객리스트</h2>
                    <div class="is-right"></div>
                </div>
                <v-data-table
                    class="jh-grid"
                    :headers="headers"
                    :items="rowNum"
                    fixed-header
                    item-key="index"
                    height="495px"
                    hide-default-footer
                    :page.sync="page"
                    @page-count="pageCount = $event">
                </v-data-table>
                <div class="jh-pager">          
                    <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
                    <v-select
                        class="jh-form is-round is-right"
                        :items="perPage"                       
                        label="10"
                        style="width: 70px"
                    ></v-select>
                    <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
                </div>
            </div>
        </main>
        <footer><v-btn class="jh-btn is-md is-main" @click="close">선택</v-btn></footer>
    </div>
</v-app>
</template>
  
  <script>
//   import api from '../../store/apiUtil.js';
  import { mixin } from "@/mixin/mixin.js";
  
  export default {
    name: "popupSample", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
    },
    components: {
    },
    data() {
      return {
        headers: [
          { text: '순번', value: 'index', align: 'center', width: '50px' },
          { text: '고객명', value: 'name', align: 'center', width: '120px' },
          { text: '생년월일', value: 'birth', align: 'center', width: '120px'},
          { text: '휴대전화', value: 'mobile', align: 'center', width: '150px' },
          { text: '일반전화', value: 'phone', align: 'center', width: '150px' },
          { text: '주소', value: 'address', align: 'center' },
        ],
        items: [
            {
                name: '홍길동',
                birth: '0000-00-00',
                mobile: '010-0000-0000',
                phone: '000-0000-0000',
                address: '주소'
            },
        ],
        page: 1,
        pageCount: 0,
        pagination  : {page : 2 , rowsPerPage : 5, sortBy : '', descending : ''},
        perPage: ['10','50','100','200'],
      };
    },
  
    created() {
    },
  
    methods: {

      close(){
        self.close();
      }
  
    },
    mounted(){
    },
    computed: {        
        rowNum() {
            return this.items.map(
                (items, index) => ({
                ...items,
                index: index + 1
            }))
        },
    },
    watch: {
    }
  };
  </script>
  
  <style></style>